import React from "react";

const TempHeader = () => {
  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5  dark:bg-gray-800 mb-4">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Zach Crutcher
          </span>
        </a>
      </div>
    </nav>
  );
};

export default TempHeader;
