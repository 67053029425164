import React from "react";
import csharpLogo from "../assets/icons/csharp-logo.png";
import aspNetLogo from "../assets/icons/aspnet-logo.png";
import sqlserverLogo from "../assets/icons/sqlserver-logo.png";
import html5Logo from "../assets/icons/html5-logo.png";
import javascriptLogo from "../assets/icons/javascript-logo.png";
import reactLogo from "../assets/icons/react-logo.png";
const Technologies = () => {
  return (
    <div className="text-center text-3xl">
      <h3 className="pt-3 pb-8">Technologies</h3>
      <div className="flex justify-center">
        <img src={csharpLogo} alt="C#" className="w-1/12 h-1/6" />
        <img src={aspNetLogo} alt="Asp.Net" className="w-1/12 h-1/6" />
        <img
          src={sqlserverLogo}
          alt="Microsoft SQL Server"
          className="w-1/12 h-1/6"
        />
        <img src={html5Logo} alt="HTML 5" className="w-1/12 h-1/6" />
        <img src={javascriptLogo} alt="JavaScript" className="w-1/12 h-1/6" />
        <img src={reactLogo} alt="React" className="w-1/12 h-1/6" />
      </div>
    </div>
  );
};

export default Technologies;
