import React from "react";
import profileImg from "../assets/images/profile-pic.png";
import githubIcon from "../assets/icons/GitHub-Mark-32px.png";
import linkedInIcon from "../assets/icons/linkedIn-Icon.png";

const AboutMe = () => {
  
  return (
    <>
      <img
        src={profileImg}
        className="h-56 sm:h-56 m-auto border-solid border-4 border-neutral-200 rounded-full"
        alt="Zach Crutcher"
      />
      <div className="text-center">
        <h2 className="text-4xl">Zach Crutcher</h2>
        <h3 className="text-2xl">Web Developer</h3>
        <div className=" m-3 flex justify-center">
          <a
            href="https://github.com/zcrutcher"
            className="flex justify-center"
            target="_blank"
          >
            <img src={githubIcon} alt="GitHub" className="h-8 w-8 mr-1" />{" "}
            GitHub
          </a>
          <a
            href="https://www.linkedin.com/in/zach-crutcher-49543055/"
            className="flex justify-center"
            target="_blank"
          >
            <img src={linkedInIcon} alt="LinkedIn" className="h-8 w-10 ml-8" />{" "}
            Linked In
          </a>
        </div>
        <hr />
      </div>

      <div class="flex flex-row">
        <div class="sm:basis-1/10 md:basis-1/8 lg:basis-1/5"></div>
        <div class="sm:basis-4/5 md:basis-3/4 lg:basis-3/5">
          <h2 className="text-3xl text-center p-3">About Me</h2>
          <div className="text-justify mx-8">
            <p className="lg: max-w-3xl m-auto">
            Hello, I’m Zach. I’m a web developer currently specializing in ASP.NET and React. I originally was a Database Administrator/Developer before migrating into web development. I love developing high quality creative solutions that improve not only our codebase, but also our users experience. Currently my responsibilities are adding new features to our ecommerce website along with migrating legacy apps into modern frameworks/languages such as React and Elm.
            </p>

          </div>
          <div className="flex justify-center p-3">            
          </div>
        </div>
        <div class="sm:basis-1/10 md:basis-1/8 lg:basis-1/5"></div>
      </div>
    </>
  );
};

export default AboutMe;
