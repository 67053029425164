import React from "react";
import ReactDOM from "react-dom";
import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import Technologies from "./components/Technologies";
import TempHeader from "./components/TempHeader";

const App = () => {
  return (
    <>
      <TempHeader />
      <AboutMe />
      <Experience />
      <Technologies />
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
